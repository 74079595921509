@import url('~@/base.less');
.loginMain {
  height: 400px;
  background-color: @primaryColor;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .loginCont {
    text-align: center;

    .title {
      color: #fff;
    }
  }
}

@primary-color: #00C7B1;