@import url('~@/base.less');
// .base-layout{
//   height: 100vh;
// }
:global {
  .qiankun-layout{
    .ant-layout-header,
    .ant-pro-global-header,
    .ant-pro-global-header-trigger {
      height: 48px !important;
    }

  }
  .no-logo{
    .ant-pro-top-nav-header-main-left{
      display: none;
    }
  }
}
// :global(.ant-pro-global-header-layout-mix .anticon) {
//   color: #333 !important;
// }
// :global(.ant-pro-sider-menu) {
//   padding-top: 12px !important;
// }
// :global{
//   .ant-layout-sider, .ant-pro-sider-menu,
//   .ant-menu-dark .ant-menu-inline.ant-menu-sub {
//     background: #000 !important;
//   }
// }
// :global(.ant-layout-sider){
//   margin-top: 12px !important;
// }
// :global(.ant-pro-basicLayout-content){
//   margin: 16px 0 0 0 !important;
// }
// .collapseIcon{
//   color: #333 !important;
//   font-size: 20px !important;
//   padding: 0 20px 0 0 !important;
// }

@primary-color: #00C7B1;