@import url('~@/base.less');
.layout-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  height: 64px;
  position: relative;
  z-index: 3;

  .base-layout-header-right{
    // padding-right: 16px;
    display: flex;
    align-items: center;
    .right-lng-icon{
      font-size: 20px;
      margin: 3px 20px 0 0;
      cursor: pointer;
    }
  }

  .console{
    margin-right: 16px;
  }
}

@primary-color: #00C7B1;