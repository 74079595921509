@import url('~@/base.less');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: 0 0;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

::-webkit-scrollbar-corner {
  background-color: #dcdcdc;
}

::-webkit-scrollbar-resizer {
  background-color: #ff6e00;
}


.main {
  background-color: #fff;
}


// 全局样式
:global {
  .table-box .ant-card-body {
    border: 1px solid #ccc;
  }

  .children-main-cont {
    padding: 16px !important;
  }
}

@primary-color: #00C7B1;