@primaryColor: #00c7b1;
@primaryColor-4: rgba(0, 199, 176, 0.1);
@btnBorderColor: #dcdcdc;
@garyBorder: #e3e3e3;
@font-color-333333: #333333;
@font-size-20: 20px;
@font-size-18: 18px;
@font-size-14: 14px;
@font-size-16: 16px;
@font-family-arial-normal: Arial Normal;

@yellow-color: #E6A23C;
@green-color: #67C23A;
@pink-color: #FF8F8F;
@gray-color: #9da6b6;
@svg-wh: 32px;

.pico-box-shadow {
  box-shadow: 0px 1px 6px 1px @primaryColor;
}

.pico-box-bg-shadow {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
}

.pico-flex-cencen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view_flex {
  display: flex;
}

.hidden-div-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.hidden-text {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.hidden-text-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// tabs为disabled的隐藏
:global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-disabled) {
  display: none !important;
}

// 玻璃磨砂效果
.glass-container {
  border-radius: 4px;
  backdrop-filter: blur(7px);
  background-color: rgba(0, 191, 255, 0.073);
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px;
  border: 1px rgba(255, 255, 255, 0.4) solid;
  border-bottom: 1px rgba(40, 40, 40, 0.35) solid;
  border-right: 1px rgba(40, 40, 40, 0.35) solid;
  background-color: rgba(250, 250, 250, 0.25);
}

:global(.ant-image-img) {
  object-fit: cover !important;
}